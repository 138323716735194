import * as React from 'react';
import TextField from '@material-ui/core/TextField';
// import { convertPixelsToRem } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Checkbox,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

/**
 *
 * Converts pixels to rems with base pixel unit of 16px
 *
 * @param  {number} unitValue - number of pixels
 * @returns string - rem value
 */
 const convertPixelsToRem = (unitValue) => {
  return `${(unitValue / 16).toFixed(2)}rem`;
};

const StringerTable = (props) => {
  const tableUnlocked = props.status !== "SUCCESSFUL";

  // used to setup styles for containers and tables
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
    },

  });

  // used to setup styles for table rows
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#eff2f8',
      },
    },
  }))(TableRow);

  // used to setup styles for short text fields
  const ShortTextField = withStyles((theme) => ({
    root: {
      width: 150,
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);


  const handleTextfieldChange = async (e, cbIdx, column, props) => {
    props.update(parseFloat(e.target.value), `data.loadcases[${cbIdx}].${column}`);
  };

  const columns = ['id', 'vz', 'my', 'vy', 'mz', 'mx', 'pressure'];


  const getField = (loadcase, column, cbIdx) => {
    return (
      <ShortTextField
        defaultValue={getValue(loadcase, column)}
        variant="outlined"
        onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
      />
    );

  };

  const getValue = (loadcase, column, digits=6) => {
    let val = loadcase[column];
    if (column === "pressure") {
      return val.toFixed(3); // always show 3 decimal places for pressure
    }
    return Math.round((val + Number.EPSILON) * 10**digits) / 10**digits // round to x digits
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && props.data && props.data.loadcases) {
      const newSelecteds = props.data.loadcases.map((_, i) => i);
      props.setSelectedRows(newSelecteds);
      return;
    }
    props.setSelectedRows([]);
  };

  const handleClick = (event, elecIdent) => {
    const selectedIndex = props.selectedRows.indexOf(elecIdent);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedRows, elecIdent);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedRows.slice(1));
    } else if (selectedIndex === props.selectedRows.length - 1) {
      newSelected = newSelected.concat(props.selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedRows.slice(0, selectedIndex),
        props.selectedRows.slice(selectedIndex + 1)
      );
    }

    props.setSelectedRows(newSelected);
  };

  const isSelected = (elecIdent) => props.selectedRows.indexOf(elecIdent) !== -1;

  const rowCount = () =>
    props.data && props.data.loadcases ? props.data.loadcases.length : 0;

  // overflowX: 'unset' to avoid small vertical scroll issue (space after the last row)
  return (
    <TableContainer className="hideScrollbar" style={{overflowX: 'unset'}}>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableUnlocked && (
              <TableCell padding="checkbox" align='center' style={{padding: 4}}>
                <Checkbox
                  color="primary"
                  size="small"
                  style={{ padding: 2 }}
                  indeterminate={
                    props.selectedRows.length > 0 && props.selectedRows.length < rowCount()
                  }
                  checked={rowCount() > 0 && props.selectedRows.length === rowCount()}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all circuit breakers',
                  }}
                />
              </TableCell>
            )}
            <TableCell align="center" style={{padding: 8}}><b>ID</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>Vz</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>My</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>Vy</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>Mz</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>Mx</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>Pressure</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.loadcases
            ? props.data.loadcases.map((loadcase, cbIdx) => {
                const isItemSelected = isSelected(cbIdx);
                console.log(isItemSelected)

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`loadcase-${cbIdx}`}
                    selected={isItemSelected}
                    // style={{ height: 41 }}
                  >
                    {tableUnlocked && (
                      <TableCell padding="checkbox" align='center'>
                        <Checkbox
                          color="primary"
                          size="small"
                          style={{ padding: 0 }}
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, cbIdx)}
                        />
                      </TableCell>
                    )}

                    {columns.map(function (column) {
                      return (
                        <TableCell
                          align={'center'}
                          key={column}
                          style={{
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingRight: 1,
                            paddingLeft: 1,
                          }}
                        >
                          {tableUnlocked
                            ? getField(loadcase, column, cbIdx)
                            : getValue(loadcase, column)}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StringerTable;
